document.addEventListener("DOMContentLoaded", (event) => {
  const submitButton = document.querySelector('input[type=submit]')
  const formTitle = document.getElementById('form-title');
  const passwordLabel = document.querySelector('label[for=account_password');
  const passwordConfirmationLabel = document.querySelector('label[for=account_password_confirmation');
  const resetPasswordToken = document.getElementById('account_reset_password_token');
  const accountConfirmationPhrase = 'ACCOUNT_CONFIRMATION_ACCOUNT_CONFIRMATION';

  const isAccountConfirmation = resetPasswordToken.value.includes(accountConfirmationPhrase);

  if (isAccountConfirmation) {
    resetPasswordToken.value = resetPasswordToken.value.replace(accountConfirmationPhrase, "");
    formTitle.textContent = "Nova senha";
    passwordLabel.textContent = "Senha";
    passwordConfirmationLabel.textContent = "Confirme a senha";
    submitButton.value = "Salvar Senha";
  }

  const passwordField = document.getElementById('account_password');
  const passwordConfirmationField = document.getElementById('account_password_confirmation');

  passwordField.addEventListener('keyup', enableDisableSubmitButton);
  passwordConfirmationField.addEventListener('keyup', enableDisableSubmitButton);

  function enableDisableSubmitButton() {
    const isSubmitButtonNotDisabled = !submitButton.disabled;
    const isPasswordInputNotEmpty = !!passwordField.value;
    const isPasswordConfirmationNotEmpty = !!passwordConfirmationField.value

    if (isPasswordInputNotEmpty && 
        isPasswordConfirmationNotEmpty &&
        isPasswordConfirmationCorrect()) {
      return submitButton.disabled = false;
    }

    if (isSubmitButtonNotDisabled) {
      return submitButton.disabled = true;
    }
  }

  function isPasswordConfirmationCorrect() {
    if (passwordField.value === passwordConfirmationField.value) {
      removePasswordConfirmationErrorMessage();
      return true;
    } else {
      setPasswordConfirmationErrorMessage();
      return false;
    }
  }

  function setPasswordConfirmationErrorMessage() {
    var errorMessageElement = document.getElementById('password-confirmation-error');
    if (!errorMessageElement) {
      errorMessageElement = document.createElement("div")
      var errorText = document.createTextNode("Senha e confirmação de senha não conferem");
      errorMessageElement.appendChild(errorText);
      errorMessageElement.setAttribute("id", "password-confirmation-error");
      errorMessageElement.classList.add("invalid-feedback");
      errorMessageElement.style.display = "contents";
      passwordConfirmationField.parentNode.insertBefore(errorMessageElement, passwordConfirmationField.nextSibling);
    }
  }

  function removePasswordConfirmationErrorMessage() {
    const errorMessageElement = document.getElementById('password-confirmation-error');
    if (errorMessageElement) {
      errorMessageElement.remove();
    }
  }
});
